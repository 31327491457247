
import './App.css';
import UnsubscribeUser from '../src/Components/UnsubscribeUser';
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import AccountManagerNotifications from './Components/AccountManagerNotifications';
import { Provider } from '@fluentui/react-northstar';

function App() {
  return (
    <div >
      <Provider>
      <Switch>
        <Route exact path="/unsubscribe/" component={UnsubscribeUser} />
        <Route exact path="/notifications/" component={AccountManagerNotifications} />
      </Switch> 
      </Provider>
    </div>
  );
}

export default App;
