import axios from "axios";
import Api from "../constants";
class apiService {
      static AuthenticatedApi = axios.create({
            baseURL: Api.notifUrl,
            // will inject auth header on-demand later as needed.
            headers: {
              "Content-Type": "application/json",
            },
          });
      static async updateUnsubscribedUser(token) {
          
         const response = await axios.get(Api.baseUrlUnsubscribe +token, {
           
        });
       
        console.log(response)
        return response;
      }
      static async GetUserTypes(token){
            const response = await this.AuthenticatedApi.get("Notification/GetUserTypes", {
                  headers: {
                        Authorization: "Bearer " + token,
                      },
            });
           
            console.log(response)
            return response;
      }
      static async GetNotificationsDetails(token){
           try
            {
                  debugger;
                  const response = await this.AuthenticatedApi.get("GetNotification", {
                        headers: {
                              Authorization: "Bearer " + token,
                            },
                  }
                  
                  );
                  console.log(response);
                  return response;
            }
            catch(err){
                  console.log("err->", err.response.data)
            }
            
            return "";
      }
      static async ViewDetails(id,token){
             var str = Api.baseurlfetchNotifbyID + id;
             debugger;
            const response = await this.AuthenticatedApi.get("GetNotificationViaId/" + id, {
                  headers: {
                        Authorization: "Bearer " + token,
                      },
            }
            );
           
            return response;
      }
    static async saveNotificationDetails(notificationDetails,token){
      debugger;
      const response = await this.AuthenticatedApi.post("SendNotification/",
                  notificationDetails,{headers: {
                        Authorization: "Bearer " + token,
                      }});
           
            console.log(response.data.data)
            return response;
      }
      
}  
export default apiService;