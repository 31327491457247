const isLocal = false;
const Api_Local = {
    baseUrlUnsubscribe: "http://localhost:5400/api/Notification/updateUnsubscribedUser/?token=",
    notifUrl : "https://localhost:44315/"
}

const Api_Prod = {
    baseUrlUnsubscribe: "https://apptest.uk.analytics-365.com/api/Notification/updateUnsubscribedUser/?token=",
    notifUrl : "https://notificationtest.ui.analytics-365.com/"
    //baseUri: "https://app.uk.analytics-365.com/api"
}
const Api = isLocal ? Api_Local : Api_Prod;
export default Api;