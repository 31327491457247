import LinkedinIcon from "../images/LinkedinIcon.png"
import twitter from "../images/TwitterIcon.png"
import microsoft from "../images/MicrosoftPartnerLogo.png"
import a365logo from "../images/logo.png"
import a365 from "../images/logo-dark.png"
import { useState, useEffect, Component } from "react";
import './AccountManagerNotifications.scss';
import apiService from '../Services/apiService';
import { Button, Text, Segment, Input, Attachment, Box, Dropdown } from "@fluentui/react-northstar";
//import parse from 'html-react-parser';
import Api from "../constants";
// import Multiselect from 'multiselect-react-dropdown';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import swal from 'sweetalert';
import SweetAlert from 'react-bootstrap-sweetalert'
import { Backdrop ,CircularProgress} from '@material-ui/core';
import '@progress/kendo-theme-default/dist/all.css';
import {
    Grid,
    GridColumn as Column,
    GridColumn,
    GridNoRecords,
} from "@progress/kendo-react-grid";
import { Editor, EditorTools } from '@progress/kendo-react-editor';
import { Upload } from "@progress/kendo-react-upload";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs"

const initialDataState = {
    skip: 0,
    take: 10,
};

const AccountManagerNotifications = (props) => {
    const [expandPlus, setexpandPlus] = useState(false);
    const [hideNotif, setHideNotif] = useState(false);
    const [isReviewOpen, setisReviewOpen] = useState(false);
    const [isNotificationOpen, setisNotificationOpen] = useState(false);
    const [isViewDetailOpen, setisViewDetailOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [userTypes, setUserTypes] = useState([]);
    const content = `<p>Dear User,</p><p>Analytics 365 needs your <strong>attention!</strong></p><p> Your billing for Analytics 365 has failed and you risk losing access to data across your organisation until payment is resumed for billing to complete.<br />Please log in to the Analytics 365 store and update your card payment details.</p><p>For assistance please <a href="https://ask.clickhelp.co/logout/?ReturnUrl=https%3A//ask.clickhelp.co/articles/analytics-365/home-page/%3Ft%3DRB4OyBgod5S4qe7df48NDD1JluMyDoil" target="_blank">visit our online help.</a></p>`;
    const [table, setTable] = useState([]);
    const [token, setToken] = useState(props.location.search.split('&').find((el) => el.includes("page=")).split('=')[1]);
    //const token = "eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9lbWFpbGFkZHJlc3MiOiJkaWtzaGEuamFpbkB0b2xscmluZy5jb20iLCJleHAiOjE2NjQ5NjUwODJ9.u5ftbNymOIUxMAxow5VcfiMHZ-iMoVG4LlTjtJPhUsk";
    const [notification, setNotification] = useState({ subject: "", message: content, attachment: [],attachmentName:"", selectedUsers: [], selectedUsersStr: "", selectedUserIds: "" });
    //const [filterCriteriaValues, setfilterCriteriaValues] = useState({ nonAdmins: false, storeAdmins: false, affiliated: false, distributers: false, Mr: false, customerAdmins: false, superAdmins: false, reportingAccess: false, insightUsers: false, falloutUsers: false, ddcustomers: false, insightNum: 0, insightPercent: 0 });
    useEffect(() => {
        
        apiService.GetNotificationsDetails(token).then(
            (response) => {
                if (response !== undefined && response !== "") {
                    debugger;
                    const newTable = response.data.map((data) =>  
                    {
                        const s = data.notificationDetail;
                        const span = document.createElement('span');
                        span.innerHTML = s;
                        data.notificationDetailtxt = span.textContent || span.innerText; 
                    }
                  );  
                    
                      console.log(response.data);
                    setLoading(false);
                    setTable(response.data);
                    

                }
                else {
                    //alert ("Something went wrong, please try again later.");  
                }
            }
        );
        apiService.GetUserTypes(token).then(
            (response) => {

                if (response !== undefined && response !== "") {
                    setUserTypes(response.data.responseText);
                    console.log(userTypes);
                }
                else {
                    //alert ("Something went wrong, please try again later.");  
                }
            }
        );

    }, []);
    const {
        Bold,
        Italic,
        Underline,
        AlignLeft,
        AlignRight,
        AlignCenter,
        Indent,
        Outdent,
        OrderedList,
        UnorderedList,
        Undo,
        Redo,
        Link,
        Unlink,
        InsertFile
    } = EditorTools;

    const [files, setFiles] = useState([]);
    const onConfirm = (event) => {
        debugger;
        window. location. reload(true) 
    }
    const onAdd = (event) => {
        console.log("onAdd: ", event.affectedFiles);
        setNotification({ ...notification, attachment: event.newState });
    };

    const onRemoveFile = (event) => {
        console.log("onRemove: ", event.affectedFiles);
        //setFiles(event.newState);
        setNotification({ ...notification, attachment: event.newState });
    };

    const onProgress = (event) => {
        console.log("onProgress: ", event.affectedFiles);
        //setFiles(event.newState);
        setNotification({ ...notification, attachment: event.newState });
    };

    const onStatusChange = (event) => {
        console.log("onStatusChange: ", event.affectedFiles);
        //setFiles(event.newState);
        setNotification({ ...notification, attachment: event.newState });
    };

    const saveDetails = (props) => {
        setLoading(true);
        apiService.saveNotificationDetails(props, token).then(
            (response) => {
                
                if (response !== undefined && response !== "" && response.status == 200) {
                    setLoading(false);
                    setisReviewOpen(false);
                    
                    swal({
                        title: "Success",
                        text: "Mails sent successfully!",
                        icon: "success",
                        button: "Ok",
                    }).then((result) => {
                            window. location. reload(true) 
                        
                      })
                    
                    
                }
                else {
                    setLoading(false);
                    //alert ("Something went wrong, please try again later.");  
                }
            }
        );

    }
    const viewDetailsfunction = (props) => {
        setexpandPlus(true);
         setisReviewOpen(true); setHideNotif(true); setisNotificationOpen(false);
        //setNotification({...notification,subject: props.dataItem.subject, message:props.dataItem.notificationDetail})
        apiService.ViewDetails(props.dataItem.notificationId, token).then(
            (response) => {
                if (response !== undefined && response !== "") {
                    debugger;
                    setNotification({ ...notification, subject: response.data.subject, message: response.data.messages, selectedUsersStr: response.data.userTypesstr, attachmentName : response.data.attachment})
                    //window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                }
                else {
                    //alert ("Something went wrong, please try again later.");  
                }
            }
        );

    }
    const onSelect = (event) => {

        let value = "", valueId = "";
        event.value.forEach(element => {
            value = value == "" ? element.userTypeName : value + "," + element.userTypeName;
            valueId = valueId == "" ? element.userTypeId : valueId + "," + element.userTypeId;
            //key = key == ""
        })
            ; setNotification({ ...notification, selectedUsers: event.value, selectedUsersStr: value, selectedUserIds: valueId.toString() });
    }
    const onRemove = (selectedList, removedItem) => {
        debugger;

        let value = "", valueId = "";
        selectedList.forEach(element => {
            value = value == "" ? element.userTypeName : value + "," + element.userTypeName;
            valueId = valueId == "" ? element.userTypeId : valueId + "," + element.userTypeId;
        }); setNotification({ ...notification, selectedUsers: selectedList, selectedUsersStr: value, selectedUserIds: valueId.toString() });
    }

    const PreviewMail = () => {
        const data = notification.message;
        
        return (
            <div
                dangerouslySetInnerHTML={{ __html: data }}
            />
        );
    }

    const ViewDetails = (props) => {
        return (
            <div className="card cardEffect">
                <div className="card-header bg-white d-flex justify-content-between">
                    View Detail
                    <Button className="close" onClick={() => { setisReviewOpen(false);handleToggle(0); }} data-target="#view-dtl" />
                    <i className="bi bi-x-lg" style={{ fontSize: '14px' }}></i>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="viewMsg">
                                <div className="viewMsgSection">
                                    <div className="viewMsgT">To</div>
                                    <div className="viewMsgRow">
                                        <div className="viewMsgTrk">
                                            <div className="viewMsgTrk-h">User Type</div>
                                            <div className="viewMsgTrk-p">
                                                <span className="viewMsgTrk-spn vdappUser" style={{ display: 'none' }}>App user</span>
                                                <span className="viewMsgTrk-spn vdreportingUser" style={{ display: 'none' }}>Reporting user</span>
                                                <span className="viewMsgTrk-spn vdbillingUser" style={{ display: 'none' }}>Billing user</span>
                                                <span className="viewMsgTrk-spn  vdresellerUsers" style={{ display: 'none' }}>Reseller Users</span>
                                            </div>
                                        </div>
                                        <div className="viewMsgTrk">
                                            <div className="viewMsgTrk-h">User Criteria</div>
                                            <div className="viewMsgTrk-p">
                                                <span className="viewMsgTrk-spn vdAllUser">All users</span>
                                                <span className="viewMsgTrk-spn vdLastDays"> No activity for last days</span>
                                                <span className="viewMsgTrk-spn vdLastHours">No activity in last hours</span>
                                            </div>
                                        </div>
                                        <div className="viewMsgTrk">
                                            <div className="viewMsgTrk-h">Reseller Criteria</div>
                                            <div className="viewMsgTrk-p">
                                                <span className="viewMsgTrk-spn vdUnderReseller">Users under all reseller</span>
                                                <span className="viewMsgTrk-spn  vdDropdown">Selected reseller </span>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="viewMsgSection">
                                    <div className="viewMsgT">Attach File</div>
                                    <div className="viewMsgRow vdFileName">

                                    </div>
                                </div>
                                <div className="viewMsgSection">
                                    <div className="viewMsgT">Subject</div>
                                    <div className="viewMsgRow vdSubject">

                                    </div>
                                </div>
                                <div className="viewMsgSection">
                                    <div className="viewMsgT">Message</div>
                                    <div className="viewMsgRow vdMessages">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }

    const cellWithViewIcon = (props) => {
        return (
            <td className="text-center">
                <i class="bi bi-eye text-primary" onClick={() => { viewDetailsfunction(props) }} style={{ fontSize: '20px', cursor: 'pointer' }}></i>
            </td>
        );
    };
    const [activeIndex, setActiveIndex] = useState(0);
    const handleToggle = (index) => {
        setActiveIndex(index);
    };

    const [page, setPage] = useState(initialDataState);

    const pageChange = (event) => {
        setPage(event.page);
    };
    const toggleDialog = () => {
        setisReviewOpen(false);
        if(!(isNotificationOpen)) setexpandPlus(false);
        handleToggle(0);
      };
      const handleSubmit = (dataItem) =>{debugger;  handleToggle(1); setisReviewOpen(true); setNotification({...notification, attachmentName:(notification.attachment.map((e) => { return (e.name + '; '); }))})  };
    return (
        <>
            <div className="dsh-customer h-100 d-flex flex-column align-items-stretch">
                <div className="dshCust-list h-100">
                    <div className="table-container table-responsive">
                        <div className="dash-trk">
                            <div className="dashInr h-100 p-0">
                                <div className="container-fluid h-100">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {expandPlus && <div className="collapseForm pb-4" id="add-note" style={{ marginTop: '0' }}>
                                                <div className="card cardEffect tabs-card">
                                                    <div className="card-header d-flex justify-content-between align-items-center pb-0">
                                                        <ul className="nav nav-tabs flex-nowrap text-center" role="tablist">
                                                            {!hideNotif && <li className="nav-item" role="presentation" style={{ cursor: 'pointer' }}>
                                                                <a className={activeIndex === 0 ? "nav-link active" : "nav-link"} id="one-tab" onClick={() => { handleToggle(0); setisReviewOpen(false); setisNotificationOpen(true); }}>
                                                                    {/* <span className="stpTrk">1</span> */}
                                                                    <span>Notification Details</span>
                                                                </a>
                                                            </li>}

                                                            {/* <li className="nav-item" role="presentation" style={{ cursor: 'pointer' }}>
                                                                <a className={activeIndex === 1 ? "nav-link active" : "nav-link"} id="three-tab" onClick={() => { handleToggle(1); setisReviewOpen(true); setNotification({...notification, attachmentName:(notification.attachment.map((e) => { return (e.name + '; '); }))}) }}>
                                                                    
                                                                    <span>Review</span>
                                                                </a>
                                                            </li> */}
                                                        </ul>
                                                        {/* <Button className="close" data-toggle="collapse" data-target="#add-note" style={{ padding: '15px' }} /> */}
                                                        <i className="bi bi-x-lg px-3 text-secondary" style={{ fontSize: '14px', cursor: 'pointer' }} onClick={() => setexpandPlus(false)} title="Close"></i>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="tab-content float-left w-100">
                                                               { loading ? 
                                                                        <Backdrop
                                                                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                                                        open
                                                                      >
                                                                        <CircularProgress color="inherit" />
                                                                      </Backdrop>
                                                                         : <Segment>
                                                                        {/* {isattachmentOpen && <Attachment {...props} />} */}
                                                                        {isReviewOpen && (<Dialog title={"Review"} onClose={toggleDialog}
                                                                                    height={"90%"}>
                                                                                    <div className="row">
                                                                                        <div className="col-md-12">
                                                                                            <div className="viewMsg">
                                                                                                <div className="viewMsgSection d-flex pb-0 border-bottom">
                                                                                                    <div className="viewMsgT border-0">To : <span className="sendTo"></span></div>
                                                                                                    <div className="viewMsgRow pt-0">
                                                                                                        <div className="viewMsgTrk pt-0 pb-0">
                                                                                                            {/* <div className="viewMsgTrk-h">User Type</div> */}
                                                                                                            <div className="viewMsgTrk-p">
                                                                                                                {notification.selectedUsersStr}
                                                                                                            </div>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="viewMsgSection d-flex align-items-center my-2 pb-0">
                                                                                                    <div className="viewMsgT text-nowrap border-0 text-secondary">Attach File :</div>
                                                                                                    <div className="viewMsgRow col py-0" id="rFileName">
                                                                                                         {notification.attachmentName} 
                                                                                                        {/*{notification.attachment.map((e) => { return (e.name + '; '); })} */}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="viewMsgT d-flex align-items-center border-0">
                                                                                                    <div className="text-nowrap text-secondary">Subject :</div>
                                                                                                    <div className="viewMsgRow pt-0 col text-center" id="rsendSubject" style={{ fontFamily: 'Inter,Arial,Helvetica,sans-serif' }}>
                                                                                                        {/* {testnotification.subject} */}
                                                                                                        {notification.subject}
                                                                                                    </div></div>
                                                                                                <div className="viewMsgSection pb-0">
                                                                                                    {/* <div className="viewMsgT">Message</div> */}
                                                                                                    <div className="viewMsgRow" id="rsendmsg">
                                                                                                        <div className="text-center">
                                                                                                            <img src={a365} alt="Analytics365" width="200" height="42" />
                                                                                                        </div>
                                                                                                        <table width="50%" cellspacing="0" cellpadding="0" border="0" align="center">
                                                                                                            <tbody>
                                                                                                                <tr>
                                                                                                                    <tr></tr>
                                                                                                                    <td id="">
                                                                                                                        <table role="presentation" id="" style={{ margin: '12px auto 0 auto', width: "100%" }}>
                                                                                                                            <tbody>
                                                                                                                                <tr>
                                                                                                                                    <td style={{ paddingRight: '8px', paddingLeft: '8px', align: 'center', backgroundColor: 'white' }}>
                                                                                                                                        <table role="presentation" style={{ maxWidth: '632px', marginTop: '0', width: "100%" }}>
                                                                                                                                            <tbody>
                                                                                                                                                <tr>
                                                                                                                                                    <td style={{ color: '#242B3D', fontSize: '16px', fontFamily: 'Inter,Arial,Helvetica,sans-serif', marginTop: '0', padding: '0 24px', lineHeight: '24px', align: "left" }}>
                                                                                                                                                        <h4 style={{ fontSize: '20px', fontFamily: 'Inter,Arial,Helvetica,sans-serif', marginTop: '0', padding: '0 0px', lineHeight: '32px', align: "left", fontWeight: '700' }}>

                                                                                                                                                        </h4>
                                                                                                                                                    </td>
                                                                                                                                                </tr>
                                                                                                                                            </tbody>
                                                                                                                                        </table>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            </tbody>
                                                                                                                        </table>
                                                                                                                        <table role="presentation" id="" width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                                                                            <tbody>
                                                                                                                                <tr>
                                                                                                                                    <td style={{ paddingRight: '8px', paddingLeft: '8px', align: 'center', backgroundColor: 'white' }}>
                                                                                                                                        <table role="presentation" style={{ maxWidth: '632px', marginTop: '0 auto' }} width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                                                                                            <tbody>
                                                                                                                                                <tr>
                                                                                                                                                    <td style={{ color: '#212121', fontSize: '16px', fontFamily: 'Inter,Arial,Helvetica,sans-serif', marginTop: '0', padding: '0 24px', lineHeight: '24px', align: "center" }}>
                                                                                                                                                        <div style={{ color: 'rgb(33, 33, 33)', fontSize: '16px', fontFamily: 'Inter, Arial, Helvetica, sans-serif, serif, EmojiFont', textAlign: 'left', marginTop: '0px', marginBottom: '0px', padding: '0px', lineHeight: '24px' }}>

                                                                                                                                                            <PreviewMail {...props} />
                                                                                                                                                        </div>
                                                                                                                                                    </td>
                                                                                                                                                </tr>
                                                                                                                                            </tbody>
                                                                                                                                        </table>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            </tbody>
                                                                                                                        </table>
                                                                                                                        <table role="presentation" id="" width="100%" cellspacing="0" cellpadding="0" border="0">
                                                                                                                            <tbody>
                                                                                                                                <tr>
                                                                                                                                    <td style={{ backgroundColor: 'white', paddingRight: '8px', paddingLeft: '8px', align: "center" }}>
                                                                                                                                        <table role="presentation" style={{ maxWidth: '632px', margin: '0' }}>
                                                                                                                                            <tbody>
                                                                                                                                                <tr>
                                                                                                                                                    <td style={{ color: '#212121', fontSize: '16px', fontFamily: 'Inter,Arial,Helvetica,sans-serif', marginTop: '0', padding: '0 24px', lineHeight: '24px' }}>
                                                                                                                                                        For more information visit
                                                                                                                                                        <a target="_blank" style={{ color: '#0265D2', fontSize: '16px', fontFamily: 'Inter,Arial,Helvetica,sans-serif', marginTop: '0', padding: '0 12px', lineHeight: '24px', fontWeight: '400' }}
                                                                                                                                                            title="" data-linkindex="3" href="https://www.analytics-365.com/">www.analytics-365.com</a>
                                                                                                                                                    </td>
                                                                                                                                                </tr>
                                                                                                                                            </tbody>
                                                                                                                                        </table>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            </tbody>
                                                                                                                        </table>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                        <table role="presentation" style={{ marginTop: '0', width: "100%" }}>
                                                                                                            <tbody>
                                                                                                                <tr>
                                                                                                                    <td style={{ backgroundColor: 'white', paddingRight: '8px', paddingBottom: '25px', paddingLeft: '8px' }} align="center">
                                                                                                                        <table role="presentation" style={{ maxWidth: '632px', margin: '0 auto', width: "100%" }}>
                                                                                                                            <tbody>
                                                                                                                                <tr>
                                                                                                                                    <td style={{ color: '#A0A3AB', fontSize: '14px', fontFamily: "Inter,Arial,Helvetica,sans-serif", backgroundColor: 'white', borderRadius: '0', marginTop: '0', marginBottom: '0', padding: '24px 24px 0 24px', lineHeight: '21px' }} align="center">
                                                                                                                                        <table role="presentation" style={{ marginTop: '0', width: "100%" }}>
                                                                                                                                            <tbody>
                                                                                                                                                <tr>
                                                                                                                                                    <td style={{ color: '#A0A3AB', fontSize: '14px', fontFamily: "Inter,Arial,Helvetica,sans-serif", backgroundColor: 'white', borderRadius: '0', marginTop: '0', marginBottom: '0', padding: '24px 24px 0 24px', lineHeight: '21px', width: '45%' }} align="right">
                                                                                                                                                        <div>
                                                                                                                                                            <p style={{ color: '#212121', fontSize: '12px', fontFamily: "Inter,Arial,Helvetica,sans-serif", fontWeight: 'bold', marginTop: '0', marginBottom: '12px', paddingRight: '20px', lineHeight: '16px' }}>
                                                                                                                                                                FOLLOW US
                                                                                                                                                            </p>
                                                                                                                                                            <div style={{ minWidth: '120px', fontSize: '14px', fontFamily: "Inter,Arial,Helvetica,sans-serif", backgroundColor: 'white', borderRadius: '0', marginTop: '0', marginBottom: '16px', paddingRight: '8px', paddingLeft: '8px', lineHeight: '21px' }}>
                                                                                                                                                                <p style={{ marginTop: '0', marginBottom: '0' }}>
                                                                                                                                                                    <a href="https://twitter.com/analytics_365" target="_blank" style={{ color: '#212121', outline: 'none' }} data-linkindex="4">
                                                                                                                                                                        <img data-imagetype="External" src={twitter} width="24" height="24" />
                                                                                                                                                                    </a>
                                                                                                                                                                    <span>&nbsp;&nbsp;</span>
                                                                                                                                                                    <a href="https://www.linkedin.com/company/analytics-365" target="_blank" style={{ color: '#212121', outline: 'none' }} data-linkindex="5">
                                                                                                                                                                        <img data-imagetype="External" src={LinkedinIcon} width="24" height="24" />
                                                                                                                                                                    </a>
                                                                                                                                                                    <span>&nbsp;&nbsp;</span>
                                                                                                                                                                    <a href="https://www.analytics-365.com" target="_blank" style={{ color: '#212121', outline: 'none' }} data-linkindex="6">
                                                                                                                                                                        <img data-imagetype="External" src={a365logo} width="23" height="23" />
                                                                                                                                                                    </a>
                                                                                                                                                                </p>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                    </td>
                                                                                                                                                    <td style={{ color: '#A0A3AB', fontSize: '14px', fontFamily: "Inter,Arial,Helvetica,sans-serif", backgroundColor: 'white', borderRadius: '0', marginTop: '0', marginBottom: '0', padding: '24px 24px 0 10px', lineHeight: '21px', width: '55%' }} align="left">
                                                                                                                                                        <div>
                                                                                                                                                            <div style={{ fontSize: '14px', fontFamily: "Inter, Arial, Helvetica, sans-serif, serif, EmojiFont", textAlign: 'center', marginTop: '0px', marginBottom: '16px', paddingRight: '8px', paddingLeft: '8px', lineHeight: '21px', width: '200px' }}>
                                                                                                                                                                <img data-imagetype="External" src={microsoft} width="200" height="56.52" />
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                    </td>
                                                                                                                                                </tr>
                                                                                                                                            </tbody>
                                                                                                                                        </table>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <td style={{ color: '#A0A3AB', fontSize: '14px', fontFamily: "Inter,Arial,Helvetica,sans-serif", backgroundColor: 'white', borderRadius: '0', marginTop: '0', marginBottom: '0', padding: '0px 24px 0 24px', lineHeight: '21px' }} align="center">
                                                                                                                                        <p style={{ fontSize: '12px', marginTop: '0', marginBottom: '4px' }}>
                                                                                                                                            <a href="https://www.analytics-365.com/privacy-policy" target="_blank" style={{ color: '#0265D2', fontWeight: 'normal', display: 'inline-block', textDecoration: 'underline', outline: 'none' }} data-linkindex="10">
                                                                                                                                                Privacy Policy
                                                                                                                                            </a>
                                                                                                                                            <span>&nbsp; | &nbsp;</span>
                                                                                                                                            <a href="https://www.analytics-365.com/gdpr" target="_blank" style={{ color: '#0265D2', fontWeight: 'normal', display: 'inline-block', textDecoration: 'underline', outline: 'none' }} data-linkindex="11">
                                                                                                                                                GDPR
                                                                                                                                            </a>

                                                                                                                                        </p>
                                                                                                                                        <p style={{ color: '#212121', fontSize: '12px', fontFamily: "Inter,Arial,Helvetica,sans-serif", marginTop: '0', marginBottom: '12px', lineHeight: '16px' }}>
                                                                                                                                            Micro Plus Software Ltd, trading as Tollring, 10 Moorcroft, Harlington Road, Uxbridge, UB8 3HD.
                                                                                                                                        </p>
                                                                                                                                        <p style={{ fontSize: '12px', marginTop: '0' }}>&copy; <script>document.write(new Date().getFullYear())</script> Tollring, All rights reserved.</p>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            </tbody>
                                                                                                                        </table>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <DialogActionsBar layout="end">
                                                                                        {!hideNotif && <Button className="btn btn-light mx-2" onClick={() => { handleToggle(0); setisReviewOpen(false); setisNotificationOpen(true); }}>Previous</Button>}
                                                                                        {!hideNotif && <Button type="submit" className="btn btn-primary" id="rSendEmail" data-toggle="collapse" data-target="#add-note" onClick={() => saveDetails({ ...notification })} >Send</Button>}
                                                                                    </DialogActionsBar>
                                                                                </Dialog>)}

                                                                        {isNotificationOpen && <div>
                                                                            <Form
                                                                                onSubmit={handleSubmit}
                                                                                render={(formRenderProps) => (
                                                                                    <FormElement
                                                                                    style={{
                                                                                       // maxWidth: 1000,
                                                                                       // minWidth: 500
                                                                                    }}
                                                                                    >
                                                                                        <fieldset className={"k-form-fieldset"}>
                                                                            <div className="row">
                                                                                <div className="col-md-12">
                                                                                    <div className="viewMsg">
                                                                                        <div className="viewMsgSection pb-0 d-flex">
                                                                                            <div className="viewMsgT border-0">To : <span className="sendTo"></span></div>
                                                                                            <div className="frm_submit float-right">
                                                                                                {/* <div className="frm_submitBt">
                                                                                                                                                <Button className="btn btn-secondary" data-toggle="modal" onClick={() =>{ setisFilterCriteriaOpen(!isFilterCriteriaOpen)} } content = "Filter Criteria"/>
                                                                                                                                            </div> */}
                                                                                            </div>
                                                                                            <div className="viewMsgRow pt-0">
                                                                                                <div className="viewMsgTrk pt-0">
                                                                                                    <div className="viewMsgTrk-h"><b>User Type *</b></div>
                                                                                                    <div className="viewMsgTrk-p">
                                                                                                        <div className="dropdownSection mb-3">
                                                                                                            {/* <Multiselect
                                                                                                                options={userTypes} // Options to display in the dropdown
                                                                                                                selectedValues={notification.selectedUsers} // Preselected value to persist in dropdown
                                                                                                                onSelect={onSelect} // Function will trigger on select event
                                                                                                                onRemove={onRemove} // Function will trigger on remove event
                                                                                                                displayValue="userTypeName" // Property name to display in the dropdown options
                                                                                                                showCheckbox= {true}
                                                                                                                //required= {true}
                                                                                                                emptyRecordMsg = {"enter a value"}
                                                                                                            /> */}
                                                                                                              <MultiSelect data={userTypes} onChange={onSelect} value={notification.selectedUsers} required 
                                                                                                              textField = "userTypeName" dataItemKey="userTypeId"/> 
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                                       
                                                                            <div className="container rounded-lg shadow-lg pt-3 px-4">
                                                                                <div className="row">
                                                                                    <div className="col-md-12 mb-2" >
                                                                                    <Field
                                                                                                name={"notification.subject"}
                                                                                                component={Input}
                                                                                                label={"Subject *"}
                                                                                                required
                                                                                                style={{
                                                                                                      width: '100%',
                                                                                                    // minWidth: 500
                                                                                                 }}
                                                                                                value = {"notification.subject"}
                                                                                                onChange={e => {  setNotification({ ...notification, subject: e.target.value }) }}
                                                                                            />
                                                                                        {/* <div>
                                                                                            <Text>Subject</Text>
                                                                                            <input
                                                                                                type="text" className="form-control mt-1" autoFocus="autoFocus"
                                                                                                value={notification.subject} onChange={e => { setNotification({ ...notification, subject: e.target.value }) }}
                                                                                            />
                                                                                            
                                                                                        </div> */}
                                                                                    </div>
                                                                                    <div className="col-md-12 mt-1">
                                                                                        <div className="upfiles">
                                                                                            <Upload
                                                                                                batch={false}
                                                                                                multiple={true}
                                                                                                files={notification.attachment}
                                                                                                onAdd={onAdd}
                                                                                                onRemove={onRemoveFile}
                                                                                                onProgress={onProgress}
                                                                                                onStatusChange={onStatusChange}
                                                                                                withCredentials={false}
                                                                                                saveUrl={Api.notifUrl + "Notification/uploadFiles"}
                                                                                                removeUrl={Api.notifUrl + "Notification/deleteFiles"}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col text-center mt-4 mb-2">
                                                                                        <img src={a365} alt="Analytics365" width="200" height="42" />
                                                                                    </div>
                                                                                    <div className="col-md-12">
                                                                                        <div className="form-group">
                                                                                            {/* <ul className="nav nav-tabs flex-nowrap text-center" role="tablist">
                                                                                                                                        <li className="nav-item" role="presentation">
                                                                                                                                         <a className="nav-link" id="three-tab" data-toggle="tab" onClick={()=> setPlaceholder("first template")} role="tab" aria-controls="Questions" aria-selected="false"></a>
                                                                                                                                         <span>body template</span>
                                                                                                                                        </li> </ul> */}
                                                                                            {/* <li className="nav-item" role="presentation">
                                                                                                                                        <a className="nav-link import '@progress/kendo-theme-default/dist/all.css';" id="two-tab" data-toggle="tab" onClick={()=> setPlaceholder("second template")} role="tab" aria-controls="Questions" aria-selected="false">
                                                                                                                                         <span>body template 2</span></a>
                                                                                                                                        </li>
                                                                                                                                        <li className="nav-item" role="presentation">
                                                                                                                                        <a className="nav-link" id="three-tab" data-toggle="tab" onClick={()=> setPlaceholder("third template")} role="tab" aria-controls="Questions" aria-selected="false">
                                                                                                                                         <span>body template 3</span></a>
                                                                                                                                        </li> */}
                                                                                            <Editor
                                                                                                tools={[
                                                                                                    [Bold, Italic, Underline],
                                                                                                    [Undo, Redo],
                                                                                                    [Link, Unlink],
                                                                                                    [AlignLeft, AlignCenter, AlignRight],
                                                                                                    [OrderedList, UnorderedList, Indent, Outdent],
                                                                                                ]}
                                                                                                contentStyle={{
                                                                                                    height: 320,
                                                                                                    width: 1000,
                                                                                                }}
                                                                                                defaultContent={notification.message}
                                                                                                content={notification.message}
                                                                                                onChange={e => { setNotification({ ...notification, message: e.html }) }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col justify-content-around d-flex">
                                                                                        <table role="presentation" style={{ marginTop: '0', width: "10%" }}>
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <td style={{ backgroundColor: 'white', paddingRight: '8px', paddingBottom: '0px', paddingLeft: '8px' }} align="left">
                                                                                                        <table role="presentation" style={{ maxWidth: '632px', margin: '0 auto', width: "100%" }}>
                                                                                                            <tbody>
                                                                                                                <tr>
                                                                                                                    <td style={{ color: '#A0A3AB', fontSize: '14px', fontFamily: "Inter,Arial,Helvetica,sans-serif", backgroundColor: 'white', borderRadius: '0', marginTop: '0', marginBottom: '0', padding: '0px 24px 0 24px', lineHeight: '21px' }} align="center">
                                                                                                                        <table role="presentation" style={{ marginTop: '0', width: "100%" }}>
                                                                                                                            <tbody>
                                                                                                                                <tr>
                                                                                                                                    <td style={{ color: '#A0A3AB', fontSize: '14px', fontFamily: "Inter,Arial,Helvetica,sans-serif", backgroundColor: 'white', borderRadius: '0', marginTop: '0', marginBottom: '0', padding: '0px 24px 0 24px', lineHeight: '21px', width: '45%' }} align="right">
                                                                                                                                        <div>
                                                                                                                                            <p style={{ color: '#212121', fontSize: '12px', fontFamily: "Inter,Arial,Helvetica,sans-serif", fontWeight: 'bold', marginTop: '0', marginBottom: '12px', paddingRight: '20px', lineHeight: '16px' }}>
                                                                                                                                                FOLLOW US
                                                                                                                                            </p>
                                                                                                                                            <div style={{ minWidth: '120px', fontSize: '14px', fontFamily: "Inter,Arial,Helvetica,sans-serif", backgroundColor: 'white', borderRadius: '0', marginTop: '0', marginBottom: '16px', paddingRight: '8px', paddingLeft: '8px', lineHeight: '21px' }}>
                                                                                                                                                <p style={{ marginTop: '0', marginBottom: '0' }}>
                                                                                                                                                    <a href="https://twitter.com/analytics_365" target="_blank" style={{ color: '#212121', outline: 'none' }} data-linkindex="4">
                                                                                                                                                        <img data-imagetype="External" src={twitter} width="24" height="24" />
                                                                                                                                                    </a>
                                                                                                                                                    <span>&nbsp;&nbsp;</span>
                                                                                                                                                    <a href="https://www.linkedin.com/company/analytics-365" target="_blank" style={{ color: '#212121', outline: 'none' }} data-linkindex="5">
                                                                                                                                                        <img data-imagetype="External" src={LinkedinIcon} width="24" height="24" />
                                                                                                                                                    </a>
                                                                                                                                                    <span>&nbsp;&nbsp;</span>
                                                                                                                                                    <a href="https://www.analytics-365.com" target="_blank" style={{ color: '#212121', outline: 'none' }} data-linkindex="6">
                                                                                                                                                        <img data-imagetype="External" src={a365logo} width="23" height="23" />
                                                                                                                                                    </a>
                                                                                                                                                </p>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </td>
                                                                                                                                    <td style={{ color: '#A0A3AB', fontSize: '14px', fontFamily: "Inter,Arial,Helvetica,sans-serif", backgroundColor: 'white', borderRadius: '0', marginTop: '0', marginBottom: '0', padding: '0px 24px 0 10px', lineHeight: '21px', width: '55%' }} align="left">
                                                                                                                                        <div>
                                                                                                                                            <div style={{ fontSize: '14px', fontFamily: "Inter, Arial, Helvetica, sans-serif, serif, EmojiFont", textAlign: 'center', marginTop: '0px', marginBottom: '16px', paddingRight: '8px', paddingLeft: '8px', lineHeight: '21px', width: '200px' }}>
                                                                                                                                                <img data-imagetype="External" src={microsoft} width="200" height="56.52" />
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            </tbody>
                                                                                                                        </table>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td style={{ color: '#A0A3AB', fontSize: '14px', fontFamily: "Inter,Arial,Helvetica,sans-serif", backgroundColor: 'white', borderRadius: '0', marginTop: '0', marginBottom: '0', padding: '0px 24px 0 24px', lineHeight: '21px' }} align="center">
                                                                                                                        <p style={{ fontSize: '12px', marginTop: '0', marginBottom: '4px' }}>
                                                                                                                            <a href="https://www.analytics-365.com/privacy-policy" target="_blank" style={{ color: '#0265D2', fontWeight: 'normal', display: 'inline-block', textDecoration: 'underline', outline: 'none' }} data-linkindex="10">
                                                                                                                                Privacy Policy
                                                                                                                            </a>
                                                                                                                            <span>&nbsp; | &nbsp;</span>
                                                                                                                            <a href="https://www.analytics-365.com/gdpr" target="_blank" style={{ color: '#0265D2', fontWeight: 'normal', display: 'inline-block', textDecoration: 'underline', outline: 'none' }} data-linkindex="11">
                                                                                                                                GDPR
                                                                                                                            </a>
                                                                                                                        </p>
                                                                                                                        <p style={{ color: '#212121', fontSize: '12px', fontFamily: "Inter,Arial,Helvetica,sans-serif", marginTop: '0', marginBottom: '12px', lineHeight: '16px' }}>
                                                                                                                            Micro Plus Software Ltd, trading as Tollring, 10 Moorcroft, Harlington Road, Uxbridge, UB8 3HD.
                                                                                                                        </p>
                                                                                                                        <p style={{ fontSize: '12px', marginTop: '0' }}>&copy; <script>document.write(new Date().getFullYear())</script> Tollring, All rights reserved.</p>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="row">
                                                                                <div className="col-md-12 text-right pt-3">
                                                                                    <div className="frm_submit">
                                                                                        <div className="frm_submitBt">
                                                                                            <Button type={'submit'} className="btn btn-primary" id="notificationDetail" content="Next" />
                                                                                            {/* onClick={() => {debugger; handleToggle(1); setisReviewOpen(true); setNotification({...notification, attachmentName:(notification.attachment.map((e) => { return (e.name + '; '); }))})  }}  */}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            </fieldset>
                                                                                         </FormElement>
                                                                                            )}
                                                                                            /> 
                                                                        </div>
                                                                        }
                                                                    </Segment>}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                    <Segment>
                                        {isViewDetailOpen && <ViewDetails {...props} />}
                                    </Segment>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card cardEffect">
                                                <div className="card-header bg-white d-flex justify-content-between">
                                                    <span>Notification List</span>
                                                    {/* {parse('<p style="color: blue">testing</p>')} */}
                                                    <div className="tbsIcn">
                                                        {/* <a className="toolboxBtn-a"  style={{cursor:'pointer'}}> data-toggle="collapse"*/}
                                                        <a className="toolboxBtn-a" onClick={() => { setexpandPlus(!expandPlus); setisNotificationOpen(!expandPlus); setisReviewOpen(false); setActiveIndex(0); setHideNotif(false);setNotification({ ...notification, message: content }) }} data-target="#add-note" style={{ cursor: 'pointer' }}>
                                                            <span data-toggle="tooltip" data-placement="left" title="Send New Notification" data-original-title="Send New Notification">
                                                                <i className="bi bi-plus-circle"></i>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>

                                                {loading ? 
                                                <Backdrop
                                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                                open
                                              >
                                                <CircularProgress color="inherit" />
                                              </Backdrop>
                                                : <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            {/* <Grid
                                                                className="table table-condensed table-bordered"
                                                                style={{ height: "100%" }}
                                                                data={table}
                                                            //sortable={true}
                                                            //sort={sort}
                                                            //onSortChange={(e) => {
                                                            //   setSort(e.sort);
                                                            //}}
                                                            >
                                                                <Column field="dateTime" title="DateTime (UTC)" width={250} locked />
                                                                <Column field="subject" title="Subject" width={400} />
                                                                <Column field="notificationDetail" title="Notification Details" width={620} />
                                                                <Column
                                                                    field="view"
                                                                    title="View"
                                                                    cell={cellWithViewIcon}
                                                                    width={150}
                                                                />
                                                            </Grid> */}
                                                            <Grid
                                                                style={{
                                                                    width: "100%",
                                                                    minWidth: "720px",
                                                                    height: "100%",
                                                                }}
                                                                data={table.slice(page.skip, page.take + page.skip)}
                                                                skip={page.skip}
                                                                take={page.take}
                                                                total={table.length}
                                                                pageable={true}
                                                                onPageChange={pageChange}
                                                            >
                                                                <GridColumn field="dateTime" title="DateTime (UTC)" width={150} />
                                                                <GridColumn field="subject" title="Subject" width={200} />
                                                                <GridColumn field="notificationDetailtxt" title="Notification Details" />
                                                                <GridColumn field="view" width={150}
                                                                    title="View"
                                                                    cell={cellWithViewIcon} />
                                                            </Grid>
                                                            <table className="table table-condensed table-bordered tbl_histNotificationscsv" style={{ display: 'none' }}>
                                                                <thead>
                                                                    <tr>
                                                                        <th>DateTime (UTC)</th>
                                                                        <th>Subject</th>
                                                                        <th>Notification Details</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                {/* {isFilterCriteriaOpen && (
        <Dialog
          title="Filter Criteria"
          onClose={() => setisFilterCriteriaOpen(false)}
          
        ><FilterCriteria {...props}/>
        </Dialog>
      )}  */}

            </div>
        </>

    );
};
export default AccountManagerNotifications;